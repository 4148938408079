import styled from 'styled-components';

export const StyledCobrandCarousel = styled.div`
  .glide {
    &__slide--active {
      justify-content: center;
    }
    &__slides {
      li {
        margin: 0;
      }
    }
  }
  .container > div {
    display: flex;
    flex-grow: 1;
  }
`;
